#MainContainer {
    min-height: 100vh;
    min-width: 100vw;
    background-color: black;  
    background-image: url('https://media.complex.com/websites/microsite-seansean/img/wavs.jpg');  
    margin: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    box-sizing: border-box;
    border-bottom: 20px solid transparent;
    border-image: url('https://media.complex.com/websites/microsite-seansean/img/fire.png') 40 round;
    font-family: 'Passion One', cursive;
}


#ticker_container {
    flex: 0;
    background-color: #ffffaa;
    width: 100%;
    box-sizing: border-box;
    border-bottom: 20px solid transparent;
    border-image: url('https://media.complex.com/websites/microsite-seansean/img/fire-upsidedown.png') 60 round;
    box-sizing: border-box;
    border-image-outset: 20px;
    padding-top: 10px;
    border-style: solid;
    border-width: 20px; 
}

.ticker_text {
    margin-right: 80px;
    font-size: 24px;
    width: 100%;
}


@media only screen and (max-width: 500px) {
    .ticker_text {
        margin-right: 20px;
        font-size: 17px;
        width: 100%;
        overflow: visible;
    }
    #ticker_container {
        /* padding-top: 15px; */
    }

  }

.ticker_text:hover {
    color: red;
}

/* Each state */
a {
    text-decoration: none;
    color: #000
}
a:visited { text-decoration: none; color:black; }
a:hover { text-decoration: none; color:red; }
a:focus { text-decoration: none; color:black; }
a:hover, a:active { text-decoration: none; color:black }

#header {
    flex: 2;
    justify-content: center;
    align-items: center;
}

.seansean_logo {
    height: 130px;
    margin-top: 30px;
    margin-bottom: 25px;
}


#video {
    flex: 2;
    width: 95%;
    height: auto;
    max-width: 650px;
    margin-bottom: 20px;
    border: red solid 2px ;
}

.video-container {
	position:relative;
	padding-bottom:56.25%;
	padding-top: 30px;
	height:0;
	overflow:hidden;
}

.video-container iframe, .video-container object, .video-container embed {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
}

.gif_gallery_header {
    color: #F60000;
    font-size: 24px;
}

#gif_gallery {
    margin-top: 25px;
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 650px;
}

.gif_row{
    width: 100%;
    flex: 1;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.gif {
    width: 100%;
}

.tag {
    flex: 1;
    width: 33.33%;
    height: auto;
    border: solid red 2px;
    margin: 10px;
}


#subscribe_cta {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
}
.cta_wrap {
    background-color: white;
    padding:10px;
}

#flames {
    flex: 1;
}